// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anonymous-bat-js": () => import("../src/pages/anonymous-bat.js" /* webpackChunkName: "component---src-pages-anonymous-bat-js" */),
  "component---src-pages-customers-js": () => import("../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-data-protection-js": () => import("../src/pages/data-protection.js" /* webpackChunkName: "component---src-pages-data-protection-js" */),
  "component---src-pages-database-upload-mtm-secure-js": () => import("../src/pages/database-upload-mtm-secure.js" /* webpackChunkName: "component---src-pages-database-upload-mtm-secure-js" */),
  "component---src-pages-database-upload-mtm-js": () => import("../src/pages/database-upload-mtm.js" /* webpackChunkName: "component---src-pages-database-upload-mtm-js" */),
  "component---src-pages-database-upload-secure-js": () => import("../src/pages/database-upload-secure.js" /* webpackChunkName: "component---src-pages-database-upload-secure-js" */),
  "component---src-pages-database-upload-js": () => import("../src/pages/database-upload.js" /* webpackChunkName: "component---src-pages-database-upload-js" */),
  "component---src-pages-events-js": () => import("../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-gdpr-js": () => import("../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-generate-demo-chat-js": () => import("../src/pages/generate-demo-chat.js" /* webpackChunkName: "component---src-pages-generate-demo-chat-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-newsroom-js": () => import("../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-partners-js": () => import("../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-password-reset-js": () => import("../src/pages/password-reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-pricing-js": () => import("../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-redirect-js": () => import("../src/pages/redirect.js" /* webpackChunkName: "component---src-pages-redirect-js" */),
  "component---src-pages-resources-js": () => import("../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-roi-calculator-js": () => import("../src/pages/roi-calculator.js" /* webpackChunkName: "component---src-pages-roi-calculator-js" */),
  "component---src-pages-statement-of-work-js": () => import("../src/pages/statement-of-work.js" /* webpackChunkName: "component---src-pages-statement-of-work-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-unsubscribe-js": () => import("../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-user-not-found-js": () => import("../src/pages/user-not-found.js" /* webpackChunkName: "component---src-pages-user-not-found-js" */),
  "component---src-pages-website-terms-js": () => import("../src/pages/website-terms.js" /* webpackChunkName: "component---src-pages-website-terms-js" */)
}

